export default {
  messages: {

    en: {
      bookNowClassNotice: 'Join the following courses with the same level of teaching materials and full class. If you are interested, please click "Course"',
      noBookNowClassMessage: 'The classes for the next session are full, please check back later.<br>(Places will be added 15 minutes before class)',
    },

    tw: {
      bookNowClassNotice: '下表為教材等級與您等級相同且還未滿堂的課程，若有興趣上課請點選 " 加入課程 "',
      noBookNowClassMessage: '下個時段的課程都已經滿堂了，請稍後再進行查詢<br>(名額將於課前15分鐘開放加選)',
    },

  },
};
